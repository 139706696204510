import React from 'react';

import {Landing} from "./views/Landing";

import './App.css';

const App = () => {

    return (
      <Landing/>
    )
}

export default App;
