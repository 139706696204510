import React from 'react';

import {EmailForm} from '../components/EmailForm';

export const Landing = () => {
    return (
        <div>
            <nav className="navbar navbar-light bg-light static-top">
                <div className="container">
                    <a className="navbar-brand" href="">ezVite.me</a>
                    <a className="btn btn-primary" target="_parent" href="https://app.ezvite.me/">Sign In</a>
                </div>
            </nav>
            <header className="masthead">
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-5">Create EZ invites to your personal events!</h1>
                                <EmailForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/*<section className="features-icons bg-light text-center">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">*/}
            {/*                    <div className="features-icons-icon d-flex"><i className="bi-window m-auto text-primary"/></div>*/}
            {/*                    <h3>No app to install</h3>*/}
            {/*                    <p className="lead mb-0">ezVite.me is text/email based so your users will not have to install yet another app.</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">*/}
            {/*                    <div className="features-icons-icon d-flex"><i className="bi-layers m-auto text-primary"/></div>*/}
            {/*                    <h3>Create your first invite in minutes</h3>*/}
            {/*                    <p className="lead mb-0">Super EZ to get started.</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="features-icons-item mx-auto mb-0 mb-lg-3">*/}
            {/*                    <div className="features-icons-icon d-flex"><i className="bi-terminal m-auto text-primary"/></div>*/}
            {/*                    <h3>No social networks!</h3>*/}
            {/*                    <p className="lead mb-0">ezVite.me is your own micro network!  You control who sees your invites.</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className="showcase">*/}
            {/*    <div className="container-fluid p-0">*/}
            {/*        <div className="row g-0">*/}
            {/*            <div className="col-lg-6 order-lg-2 text-white showcase-img showcase-img-1"/>*/}
            {/*            <div className="col-lg-6 order-lg-1 my-auto showcase-text">*/}
            {/*                <h2>Fully Responsive Design</h2>*/}
            {/*                <p className="lead mb-0">*/}
            {/*                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Feugiat sed lectus vestibulum mattis. Vitae suscipit tellus mauris a diam maecenas sed enim. Quisque non tellus orci ac auctor augue mauris augue neque.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row g-0">*/}
            {/*            <div className="col-lg-6 text-white showcase-img showcase-img-2"/>*/}
            {/*            <div className="col-lg-6 my-auto showcase-text">*/}
            {/*                <h2>Updated For Bootstrap 5</h2>*/}
            {/*                <p className="lead mb-0">*/}
            {/*                    Mus mauris vitae ultricies leo integer. Ac placerat vestibulum lectus mauris ultrices eros. Vitae turpis massa sed elementum tempus egestas sed.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row g-0">*/}
            {/*            <div className="col-lg-6 order-lg-2 text-white showcase-img showcase-img-3"/>*/}
            {/*            <div className="col-lg-6 order-lg-1 my-auto showcase-text">*/}
            {/*                <h2>Easy to Use & Customize</h2>*/}
            {/*                <p className="lead mb-0">*/}
            {/*                    Magna ac placerat vestibulum lectus mauris. Enim nec dui nunc mattis enim ut tellus. Scelerisque viverra mauris in aliquam sem fringilla ut morbi. Ut consequat semper viverra nam libero. Nunc lobortis mattis aliquam faucibus purus.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className="testimonials text-center bg-light">*/}
            {/*    <div className="container">*/}
            {/*        <h2 className="mb-5">What people are saying...</h2>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial-item mx-auto mb-5 mb-lg-0">*/}
            {/*                    <img className="img-fluid rounded-circle mb-3" src="assets/img/testimonials-1.jpg" alt="..." />*/}
            {/*                    <h5>Margaret E.</h5>*/}
            {/*                    <p className="font-weight-light mb-0">"This is fantastic! ezVite.me solved my group text madness!"</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial-item mx-auto mb-5 mb-lg-0">*/}
            {/*                    <img className="img-fluid rounded-circle mb-3" src="assets/img/testimonials-2.jpg" alt="..." />*/}
            {/*                    <h5>Fred S.</h5>*/}
            {/*                    <p className="font-weight-light mb-0">"ezVite.me is amazing. I've been using it to create invitations for my beach volleyball group."</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial-item mx-auto mb-5 mb-lg-0">*/}
            {/*                    <img className="img-fluid rounded-circle mb-3" src="assets/img/testimonials-3.jpg" alt="..." />*/}
            {/*                    <h5>Sarah W.</h5>*/}
            {/*                    <p className="font-weight-light mb-0">"ezVite.me is such a time saver when I just want to know who can come to my wine tastings."</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className="call-to-action text-white text-center" id="signup">*/}
            {/*    <div className="container position-relative">*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="col-xl-6">*/}
            {/*                <h2 className="mb-4">Ready to get started? Sign up now!</h2>*/}
            {/*                <EmailForm/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <footer className="footer bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 h-100 text-center text-lg-start my-auto">
                            <ul className="list-inline mb-2">
                                <li className="list-inline-item"><a href="">About</a></li>
                                <li className="list-inline-item">⋅</li>
                                <li className="list-inline-item"><a href="">Contact</a></li>
                                <li className="list-inline-item">⋅</li>
                                <li className="list-inline-item"><a href="">Terms of Use</a></li>
                                <li className="list-inline-item">⋅</li>
                                <li className="list-inline-item"><a href="">Privacy Policy</a></li>
                            </ul>
                            <p className="text-muted small mb-4 mb-lg-0">&copy; ezVite.me 2021. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
