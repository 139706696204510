import React, {useState} from 'react';
import axios from 'axios';

import './Form.css'

const {REACT_APP_API_SERVER} = process.env;

const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const EmailForm = () => {

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    const val = e.currentTarget.value;
    setEmail(val);
    setValidEmail(emailRegEx.test(val));
  }

  const handleFormSubmit = () => {
    setError('');
    if (!emailRegEx.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    axios.post(`${REACT_APP_API_SERVER}/admin/simple_create_email`, {
      email
    }).then((response) => {
      console.log('result', response);
      if (response.status === 201) {
        setSuccess(true);
      }
    }).catch((err) => {
      setError(err.response.data.error || 'There was an error');
    });
  }

  if (success) {
    return (
      <p className='success'>
        Welcome to ezVite.me!<br/>
        We will send an email to the following email address to get you started.<br/>
        <span className='email'>{email}</span>
      </p>
    )
  }

  return (
    <form>
      {error &&
        <div className='error'>
          {error}
        </div>
      }

      <div className="input-group input-group-lg">
        <input
          className='form-control'
          type='email'
          placeholder='email address'
          value={email}
          onChange={handleEmailChange} />

        <button className="btn btn-primary" type="button" disabled={!validEmail} onClick={handleFormSubmit}>Get Started!</button>
      </div>
    </form>
  )
}
